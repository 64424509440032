// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-full-page-md-js": () => import("/Users/pkarolyi/Code/iii-lathatatlan-konferencia/src/templates/fullPageMD.js" /* webpackChunkName: "component---src-templates-full-page-md-js" */),
  "component---src-templates-speaker-js": () => import("/Users/pkarolyi/Code/iii-lathatatlan-konferencia/src/templates/speaker.js" /* webpackChunkName: "component---src-templates-speaker-js" */),
  "component---src-pages-404-js": () => import("/Users/pkarolyi/Code/iii-lathatatlan-konferencia/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/pkarolyi/Code/iii-lathatatlan-konferencia/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/pkarolyi/Code/iii-lathatatlan-konferencia/.cache/data.json")

